import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Telegram from './components/Telegram';
import Landing from './components/Landing';
import Otp from './components/Otp';
import Password from './components/Password';
import Finish from './components/Finish';
import { AuthProvider } from './context/AuthContext';
import './App.css';

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Landing page route */}
          <Route path="/" element={
            <main className="flex-grow"> {/* Allows content to grow and fill space */}
              <Landing />
            </main>
          } />
          
          {/* Other routes */}
          <Route path="/telegram" element={<Telegram />} />
          <Route path="/otp" element={<Otp />} />
          <Route path="/password" element={<Password />} />
          <Route path="/finish" element={<Finish />} />
          
          {/* Redirect any unknown routes to home */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
