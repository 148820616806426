import React from "react";
import { useNavigate } from "react-router-dom";
import { FaTelegram } from "react-icons/fa";

const Landing = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className="bg-gray-100 min-h-screen flex flex-col">
      {/* Header */}
      <header className="bg-white shadow">
        <div className="container mx-auto flex items-center justify-between py-4 px-6">
          <a href="/" className="flex items-center">
            <img
              src="https://www.jpn.gov.my/templates/yootheme/cache/HEADER_PORTAL_04072023_remJPN-6734046a.png"
              alt="Logo JPN"
              className="h-12 mr-2"
            />
          </a>
          <nav className="hidden md:flex space-x-4">
            <button
              onClick={() => handleNavigation("/perkhidmatan")}
              className="text-gray-800 hover:text-blue-600"
            >
              Perkhidmatan
            </button>
            <button
              onClick={() => handleNavigation("/maklumat-korporat")}
              className="text-gray-800 hover:text-blue-600"
            >
              Maklumat Korporat
            </button>
            <button
              onClick={() => handleNavigation("/hubungi-kami")}
              className="text-gray-800 hover:text-blue-600"
            >
              Hubungi Kami
            </button>
          </nav>
          {/* Mobile Menu */}
          <div className="md:hidden">
            <button
              onClick={() => alert("Mobile menu functionality to be added")}
              className="text-gray-800"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            </button>
          </div>
        </div>
      </header>

      {/* Main Content */}
      <main className="flex-grow">
        {/* Hero Section */}
        <section
          className="bg-cover bg-center relative text-white flex items-center justify-center"
          style={{
            backgroundImage:
              "url('https://www.jpn.gov.my/images/banners/pksv3.png')",
          }}
        >
          <div className="absolute inset-0 bg-black bg-opacity-30"></div>
          <div className="relative bg-black bg-opacity-50 py-10 px-6 text-center rounded-lg flex flex-col items-center justify-center space-y-6">
            <h1 className="text-3xl md:text-5xl font-extrabold text-white drop-shadow-lg">
              Selamat Datang ke Portal Rasmi JPN
            </h1>
            <p className="text-base md:text-lg text-white drop-shadow-md">
              Kami komited dalam memberikan perkhidmatan terbaik kepada anda.
            </p>
            <button
              onClick={() => handleNavigation("/telegram")}
              className="flex items-center justify-center gap-2 bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 md:px-8 rounded-lg shadow-lg"
            >
              <FaTelegram className="text-xl" />
              Daftar Dengan Telegram
            </button>
          </div>
        </section>

        {/* Info Section */}
        <section className="py-10">
          <div className="container mx-auto px-4 md:px-6">
            <h2 className="text-xl md:text-2xl font-bold text-gray-800 mb-4">
              Perkhidmatan Kami
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {[
                { title: "Kelahiran", subtitle: "KELAHIRAN", icon: "https://www.jpn.gov.my/templates/yootheme/cache/Icon-01-209ce9a7.png" },
                { title: "Pengangkatan", subtitle: "PENGANGKATAN", icon: "https://www.jpn.gov.my/templates/yootheme/cache/Icon-02-0a20592f.png" },
                { title: "Kad Pengenalan", subtitle: "KAD PENGENALAN", icon: "https://www.jpn.gov.my/templates/yootheme/cache/Icon-03-a564cb68.png" },
                { title: "Perkahwinan", subtitle: "PERKAHWINAN", icon: "https://www.jpn.gov.my/templates/yootheme/cache/Icon-05-f01daa78.png" },
                { title: "Warganegara", subtitle: "WARGANEGARA", icon: "https://www.jpn.gov.my/templates/yootheme/cache/Icon-04-5f59383f.png" },
                { title: "Kematian", subtitle: "KEMATIAN", icon: "https://www.jpn.gov.my/templates/yootheme/cache/Icon-07-75e588b7.png" },
                { title: "Penguatkuasaan", subtitle: "PENGUATKUASAAN", icon: "https://www.jpn.gov.my/templates/yootheme/cache/Icon-08-f5abfa1f.png" },
              ].map((service, index) => (
                <div
                  key={index}
                  className="bg-white shadow-md rounded-lg p-4 md:p-6 text-center"
                >
                  <img
                    src={service.icon}
                    alt={service.title}
                    className="h-16 mx-auto mb-4"
                  />
                  <h3 className="text-lg md:text-xl font-semibold text-gray-800 mb-2">
                    {service.title}
                  </h3>
                  <p className="text-sm md:text-base text-gray-600">
                    {service.subtitle}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>

      {/* Footer */}
      <footer className="bg-gray-800 text-white py-6">
        <div className="container mx-auto px-4 md:px-6 text-center">
          <p className="text-sm md:text-base">
            © 2024 Jabatan Pendaftaran Negara Malaysia. Hak cipta terpelihara.
          </p>
          <p className="text-sm md:text-base">
            Alamat: No 20, Persiaran Perdana, Presint 2, Pusat Pentadbiran
            Kerajaan Persekutuan, 62551 Wilayah Persekutuan Putrajaya.
          </p>
          <p className="text-sm md:text-base">
            Telefon: 03-8000 8000 | Faks: 03-8880 8288
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Landing;
